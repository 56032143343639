import React from 'react';
import './styles/index.scss';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AlertProvider } from './components/alerts/AlertContext';
import { ApiProvider } from './service/ApiContext';
import Index from "./components/index/VerificacaoDeEmail.jsx";
import NotFound from "./components/notFound/NotFound";
import MainLayout from "./components/layouts/MainLayout";

function App() {
  return (
    <AlertProvider>
      <ApiProvider>
        <Router>
          <MainLayout>
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </MainLayout>
        </Router>
      </ApiProvider>
    </AlertProvider>
  );
}

export default App;

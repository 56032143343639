import { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <header className="bg-[#3991CF]">
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex items-center space-x-6">
                    <a href="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">Click ideia</span>
                        <img className="h-8 w-auto" src="/img/logo-lg.png" alt="Click ideia" />
                    </a>
                    <div className="hidden lg:flex lg:space-x-6">
                        <a href="https://www.home.clickideia.com.br/quem-somos" target='_blank' rel="noreferrer" className="text-lg font-semibold leading-6 text-white hover:text-gray-200 hover:bg-[#297BA3] p-2 rounded">
                            Quem somos
                        </a>
                        <a href="https://www.home.clickideia.com.br/midia" target='_blank' rel="noreferrer" className="text-lg font-semibold leading-6 text-white hover:text-gray-200 hover:bg-[#297BA3] p-2 rounded">
                            Midia
                        </a>
                        <a href="https://www.home.clickideia.com.br/contato" target='_blank' rel="noreferrer" className="text-lg font-semibold leading-6 text-white hover:text-gray-200 hover:bg-[#297BA3] p-2 rounded">
                            Contato
                        </a>
                    </div>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Abrir menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <a href="https://www.clickideia.com.br/login/" className="text-lg font-semibold leading-6 text-white hover:text-gray-200 hover:bg-[#297BA3] p-2 rounded">
                        Entrar <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </nav>

            <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-[#3991CF] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="https://www.home.clickideia.com.br/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Click ideia</span>
                            <img className="h-8 w-auto" src="/img/logo-sm.png" alt="" />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-white"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <a
                                    href="https://www.home.clickideia.com.br/quem-somos" target='_blank' rel="noreferrer"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-[#3991CF]"
                                >
                                    Quem somos
                                </a>
                                <a
                                    href="https://www.home.clickideia.com.br/midia" target='_blank' rel="noreferrer"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-[#3991CF]"
                                >
                                    Midia
                                </a>
                                <a
                                    href="https://www.home.clickideia.com.br/contato" target='_blank' rel="noreferrer"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-[#3991CF]"
                                >
                                    Contato
                                </a>
                            </div>
                            <div className="py-6">
                                <a
                                    href="https://www.clickideia.com.br/login/" target='_blank' rel="noreferrer"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-[#3991CF]"
                                >
                                    Entrar
                                </a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    );
}

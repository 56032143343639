import React, { useState, useEffect } from 'react';
import CadastroSucesso from './CadastroSucesso';
import { FaSpinner } from 'react-icons/fa';
import { isValidCPF, formatCPF } from '@brazilian-utils/brazilian-utils';

const Cadastro = ({ token, email }) => {
    const [nome, setNome] = useState('');
    const [sexo, setSexo] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const [rg, setRg] = useState('');
    const [cpf, setCpf] = useState('');
    const [escola, setEscola] = useState('');
    const [etapaEnsino, setEtapaEnsino] = useState('');
    const [optionValueSchool, setOptionValueSchool] = useState([]);
    const [optionValueLevel, setOptionValueLevel] = useState([]);
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeCPF = (event) => {
        const value = event.target.value.replace(/\D/g, '');
        setCpf(formatCPF(value));
    };

    useEffect(() => {
        const handleDataAcademic = async () => {
            try {
                const response = await window.api.get('/registration/teacher-academic-data', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    setOptionValueSchool(response?.data?.data?.escolas);
                }
            } catch (error) {
                console.log(error);
                window.showGlobalAlert(error?.response?.data, 'error');
            }
        };
        handleDataAcademic();
    }, [token]);

    useEffect(() => {
        if (escola) {
            const selectedSchool = optionValueSchool.find(item => item.id === parseInt(escola));
            if (selectedSchool) {
                setOptionValueLevel(selectedSchool.etapa);
            } else {
                setOptionValueLevel([]);
            }
        } else {
            setOptionValueLevel([]);
        }
    }, [escola, optionValueSchool]);

    const validate = () => {
        let tempErrors = {};
        tempErrors.nome = nome ? "" : "Nome é obrigatório.";
        tempErrors.sexo = sexo ? "" : "Sexo é obrigatório.";
        tempErrors.dataNascimento = dataNascimento ? "" : "Data de nascimento é obrigatória.";
        tempErrors.rg = rg ? "" : "RG é obrigatório.";
        tempErrors.cpf = cpf ? (isValidCPF(cpf) ? "" : "CPF inválido.") : "CPF é obrigatório.";
        tempErrors.escola = escola ? "" : "Escola é obrigatória.";
        tempErrors.etapaEnsino = etapaEnsino ? "" : "Etapa de ensino é obrigatória.";
        setErrors(tempErrors);

        return Object.values(tempErrors).every(x => x === "");
    };

    const handleDataNascimentoChange = (e) => {
        const value = e.target.value;
        const year = value.split('-')[0];
        if (year.length <= 4) {
            setDataNascimento(value);
        }
    };

    const handleChangeNome = (e) => {
        const value = e.target.value;
        if (/^[a-zA-ZÀ-ÿ\s]*$/.test(value)) {
            setNome(value);
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            setIsLoading(true);
            try {
                const response = await window.api.post('/registration/teacher-data', {
                    nome,
                    sexo,
                    data_nascimento: dataNascimento,
                    cpf,
                    rg,
                    school: escola,
                    level: etapaEnsino
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    setIsSubmitted(true);
                }
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.message === "The cpf has already been taken.") {
                    return window.showGlobalAlert('CPF informado já está associado a uma conta. Por favor, entre em contato no "ajuda com acesso"', 'error');
                } else {
                    window.showGlobalAlert(error?.response?.data, 'error');
                }
            } finally {
                setIsLoading(false);
            }
        } else {
            window.showGlobalAlert('Por favor, preencha todos os campos obrigatórios.', 'error');
        }
    };

    return (
        <>
            {isSubmitted ? (
                <CadastroSucesso email={email} />
            ) : (
                <div className="cadastro-container flex flex-col items-center p-6 bg-white rounded-md">
                    <h2 className="text-xl font-semibold mb-10">Autocadastro Professor</h2>
                    <form className="w-full max-w-md" onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nome">
                                Seu nome completo
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
                                id="nome"
                                type="text"
                                placeholder="Insira seu nome completo"
                                value={nome}
                                onChange={handleChangeNome}
                            />
                            {errors.nome && <p className="text-red-500 text-xs italic">{errors.nome}</p>}
                        </div>
                        <div className="mb-4">
                            <span className="block text-gray-700 text-sm font-bold mb-2">Sexo</span>
                            <label className="inline-flex items-center mr-4">
                                <input
                                    className="form-radio text-[#44C2F8] focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
                                    type="radio"
                                    name="sexo"
                                    value="Masculino"
                                    checked={sexo === 'Masculino'}
                                    onChange={(e) => setSexo(e.target.value)}
                                />
                                <span className="ml-2">Masculino</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    className="form-radio text-[#44C2F8] focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
                                    type="radio"
                                    name="sexo"
                                    value="Feminino"
                                    checked={sexo === 'Feminino'}
                                    onChange={(e) => setSexo(e.target.value)}
                                />
                                <span className="ml-2">Feminino</span>
                            </label>
                            {errors.sexo && <p className="text-red-500 text-xs italic">{errors.sexo}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dataNascimento">
                                Data de nascimento
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
                                id="dataNascimento"
                                type="date"
                                placeholder="Insira sua data de nascimento"
                                value={dataNascimento}
                                onChange={handleDataNascimentoChange}
                            />
                            {errors.dataNascimento && <p className="text-red-500 text-xs italic">{errors.dataNascimento}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rg">
                                RG
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
                                id="rg"
                                type="text"
                                placeholder="Insira seu RG"
                                value={rg}
                                maxLength={10}
                                onChange={(e) => setRg(e.target.value)}
                            />
                            {errors.rg && <p className="text-red-500 text-xs italic">{errors.rg}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cpf">
                                CPF
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
                                id="cpf"
                                type="text"
                                placeholder="Insira seu CPF"
                                value={cpf}
                                maxLength={14}
                                onChange={handleChangeCPF}
                            />
                            {errors.cpf && <p className="text-red-500 text-xs italic">{errors.cpf}</p>}
                        </div>
                        <div className="flex mb-4">
                            <div className="w-1/2 mr-2">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="escola">
                                    Escola
                                </label>
                                <select
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
                                    id="escola"
                                    value={escola}
                                    onChange={(e) => setEscola(e.target.value)}
                                >
                                    <option value="">Selecione sua escola</option>
                                    {optionValueSchool.map((item) => (
                                        <option key={item.id} value={item.id}>{item.nome}</option>
                                    ))}
                                </select>
                                {errors.escola && <p className="text-red-500 text-xs italic">{errors.escola}</p>}
                            </div>
                            <div className="w-1/2 ml-2">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="etapa-ensino">
                                    Etapa de ensino
                                </label>
                                <select
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
                                    id="etapa-ensino"
                                    value={etapaEnsino}
                                    onChange={(e) => setEtapaEnsino(e.target.value)}
                                >
                                    <option value="">Selecione a etapa de ensino</option>
                                    {optionValueLevel.map((item) => (
                                        <option key={item.id} value={item.id}>{item.label}</option>
                                    ))}
                                </select>
                                {errors.etapaEnsino && <p className="text-red-500 text-xs italic">{errors.etapaEnsino}</p>}
                            </div>
                        </div>
                        <div className="flex justify-center mt-5">
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="flex w-1/2 justify-center rounded-full bg-[#44C2F8] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3AB3E2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#44C2F8] disabled:opacity-50"
                            >
                                {isLoading ? (
                                    <FaSpinner className="animate-spin h-5 w-5 text-white" />
                                ) : (
                                    'Cadastrar'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

export default Cadastro;

import React, { createContext, useState, useContext, useEffect } from 'react';
import AlertComponent from './Alert';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');

    const showAlert = (message, severity = 'success') => {
        setAlertMessage(message);
        setAlertSeverity(severity);
        setAlertOpen(true);
    };

    const closeAlert = () => {
        setAlertOpen(false);
    };

    useEffect(() => {
        window.showGlobalAlert = showAlert;
    }, []);

    return (
        <AlertContext.Provider value={{ showAlert }}>
            {children}
            <AlertComponent
                open={alertOpen}
                message={alertMessage}
                severity={alertSeverity}
                onClose={closeAlert}
            />
        </AlertContext.Provider>
    );
};

export const useAlert = () => useContext(AlertContext);

import React, { useState, useEffect } from 'react';
import CadastroSucesso from './CadastroSucesso';
import { FaSpinner } from 'react-icons/fa';

const Cadastro = ({ token, email }) => {
  const [nome, setNome] = useState('');
  const [sexo, setSexo] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [escola, setEscola] = useState('');
  const [etapaEnsino, setEtapaEnsino] = useState('');
  const [serie, setSerie] = useState('');
  const [turma, setTurma] = useState('');
  const [optionValueSchool, setOptionValueSchool] = useState([]);
  const [filteredLevels, setFilteredLevels] = useState([]);
  const [filteredSeries, setFilteredSeries] = useState([]);
  const [optionValueTurma, setOptionValueTurma] = useState([]);
  const [errors, setErrors] = useState({});
  const [cadastroSucesso, setCadastroSucesso] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleDataAcademic = async () => {
      try {
        const response = await window.api.get('/registration/student-academic-data', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.status === 200) {
          setOptionValueSchool(response?.data?.data?.escolas);
        }
      } catch (error) {
        console.log(error);
        window.showGlobalAlert(error?.response?.data, 'error');
      }
    };
    handleDataAcademic();
  }, [token]);

  useEffect(() => {
    if (escola) {
      const selectedSchool = optionValueSchool.find(school => school.id === parseInt(escola));
      setFilteredLevels(selectedSchool ? selectedSchool.etapa : []);
      setFilteredSeries([]);
      setEtapaEnsino('');
      setSerie('');
      setTurma('');
    } else {
      setFilteredLevels([]);
      setFilteredSeries([]);
      setEtapaEnsino('');
      setSerie('');
      setTurma('');
    }
  }, [escola, optionValueSchool]);

  useEffect(() => {
    if (etapaEnsino && escola) {
      const selectedSchool = optionValueSchool.find(school => school.id === parseInt(escola));
      const selectedLevel = selectedSchool ? selectedSchool.etapa.find(level => level.id === parseInt(etapaEnsino)) : null;
      const filtered = selectedSchool && selectedLevel ? selectedSchool.serie.filter(serie => serie.nivel === selectedLevel.id) : [];
      setFilteredSeries(filtered);
      setSerie('');
      setTurma('');
    } else {
      setFilteredSeries([]);
      setSerie('');
      setTurma('');
    }
  }, [etapaEnsino, escola, optionValueSchool]);

  const handleTurmaFetch = async (school, level, serie) => {
    try {
      const response = await window.api.get('/registration/student-academic-classes', {
        params: {
          school,
          level,
          serie
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        setOptionValueTurma(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
      window.showGlobalAlert(error?.response?.data, 'error');
    }
  };

  useEffect(() => {
    if (escola && etapaEnsino && serie) {
      handleTurmaFetch(escola, etapaEnsino, serie);
    }
    // eslint-disable-next-line
  }, [escola, etapaEnsino, serie]);

  const validate = () => {
    let tempErrors = {};
    tempErrors.nome = nome ? "" : "Nome é obrigatório.";
    tempErrors.sexo = sexo ? "" : "Sexo é obrigatório.";
    tempErrors.dataNascimento = dataNascimento ? "" : "Data de nascimento é obrigatória.";
    tempErrors.escola = escola ? "" : "Escola é obrigatória.";
    tempErrors.etapaEnsino = etapaEnsino ? "" : "Etapa de ensino é obrigatória.";
    tempErrors.serie = serie ? "" : "Série é obrigatória.";
    tempErrors.turma = turma ? "" : "Turma é obrigatória.";
    setErrors(tempErrors);

    return Object.values(tempErrors).every(x => x === "");
  };

  const handleDataNascimentoChange = (e) => {
    const value = e.target.value;
    const year = value.split('-')[0];
    if (year.length <= 4) {
      setDataNascimento(value);
    }
  };

  const handleChangeNome = (setter) => (e) => {
    const value = e.target.value;
    if (/^[a-zA-ZÀ-ÿ\s]*$/.test(value)) {
      setter(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        const response = await window.api.post('/registration/student-data', {
          nome,
          sexo,
          filiacao: "Não informado",
          data_nascimento: dataNascimento,
          school: escola,
          level: etapaEnsino,
          serie,
          class: turma
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.status === 200) {
          setCadastroSucesso(true);
        }
      } catch (error) {
        console.log(error);
        window.showGlobalAlert('Erro ao realizar cadastro. Tente novamente mais tarde.', 'error');
      } finally {
        setIsLoading(false);
      }
    } else {
      window.showGlobalAlert('Por favor, preencha todos os campos obrigatórios.', 'error');
    }
  };

  if (cadastroSucesso) {
    return <CadastroSucesso email={email} />;
  }

  return (
    <div className="cadastro-container flex flex-col items-center p-6 bg-white rounded-md">
      <h2 className="text-xl font-semibold mb-10">Autocadastro Aluno</h2>
      <form className="w-full max-w-md" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nome">
            Seu nome completo
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
            id="nome"
            type="text"
            placeholder="Insira seu nome completo"
            value={nome}
            onChange={handleChangeNome(setNome)}
          />
          {errors.nome && <p className="text-red-500 text-xs italic">{errors.nome}</p>}
        </div>
        <div className="mb-4">
          <span className="block text-gray-700 text-sm font-bold mb-2">Sexo</span>
          <label className="inline-flex items-center mr-4">
            <input
              className="form-radio text-[#44C2F8] focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
              type="radio"
              name="sexo"
              value="Masculino"
              checked={sexo === 'Masculino'}
              onChange={(e) => setSexo(e.target.value)}
            />
            <span className="ml-2">Masculino</span>
          </label>
          <label className="inline-flex items-center">
            <input
              className="form-radio text-[#44C2F8] focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
              type="radio"
              name="sexo"
              value="Feminino"
              checked={sexo === 'Feminino'}
              onChange={(e) => setSexo(e.target.value)}
            />
            <span className="ml-2">Feminino</span>
          </label>
          {errors.sexo && <p className="text-red-500 text-xs italic">{errors.sexo}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dataNascimento">
            Data de nascimento
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
            id="dataNascimento"
            type="date"
            placeholder="Insira sua data de nascimento"
            value={dataNascimento}
            onChange={handleDataNascimentoChange}
          />
          {errors.dataNascimento && <p className="text-red-500 text-xs italic">{errors.dataNascimento}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="escola">
            Qual sua escola?
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
            id="escola"
            value={escola}
            onChange={(e) => setEscola(e.target.value)}
          >
            <option value="">Selecione sua escola</option>
            {optionValueSchool.map((item) => (
              <option key={item.id} value={item.id}>{item.nome}</option>
            ))}
          </select>
          {errors.escola && <p className="text-red-500 text-xs italic">{errors.escola}</p>}
        </div>
        <div className="flex mb-4">
          <div className="w-1/2 mr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="etapaEnsino">
              Etapa de ensino
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
              id="etapaEnsino"
              value={etapaEnsino}
              onChange={(e) => setEtapaEnsino(e.target.value)}
            >
              <option value="">Selecione a etapa de ensino</option>
              {filteredLevels.map((item) => (
                <option key={item.id} value={item.id}>{item.label}</option>
              ))}
            </select>
            {errors.etapaEnsino && <p className="text-red-500 text-xs italic">{errors.etapaEnsino}</p>}
          </div>
          <div className="w-1/2 ml-2">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="serie">
              Série
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
              id="serie"
              value={serie}
              onChange={(e) => setSerie(e.target.value)}
            >
              <option value="">Selecione a série</option>
              {filteredSeries.map((item) => (
                <option key={item.id} value={item.id}>{item.label}</option>
              ))}
            </select>
            {errors.serie && <p className="text-red-500 text-xs italic">{errors.serie}</p>}
          </div>
        </div>
        {escola && etapaEnsino && serie && (
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="turma">
              Turma
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset focus:ring-[#44C2F8]"
              id="turma"
              value={turma}
              onChange={(e) => setTurma(e.target.value)}
            >
              <option value="">Selecione a turma</option>
              {optionValueTurma.map((item) => (
                <option key={item.id} value={item.id}>{item.label}</option>
              ))}
            </select>
            {errors.turma && <p className="text-red-500 text-xs italic">{errors.turma}</p>}
          </div>
        )}
        <div className="flex justify-center mt-5">
          <button
            type="submit"
            disabled={isLoading}
            className="flex w-1/2 justify-center rounded-full bg-[#44C2F8] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3AB3E2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#44C2F8] disabled:opacity-50"
          >
            {isLoading ? (
              <FaSpinner className="animate-spin h-5 w-5 text-white" />
            ) : (
              'Cadastrar'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Cadastro;

import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-[#3991CF] text-white py-4">
      <div className="container mx-auto flex flex-wrap-reverse justify-between items-stretch max-w-7xl px-6 lg:px-8">
        <div className="text-sm mt-4 mt-md-0 mb-4 whitespace-nowrap">
          © 2024 | Clickideia | Todos os direitos reservados
        </div>
        <div className="flex items-center space-x-4">
          <a href="https://www.home.clickideia.com.br/pol%C3%ADtica-de-privacidade" target="_blank" rel="noreferrer" className="underline text-sm">
            Política de Privacidade
          </a>
          <a href="https://www.facebook.com/PortalClickideia/" target="_blank" rel="noreferrer" className="text-white hover:text-gray-200">
            <FaFacebook size={24} />
          </a>
          <a href="https://www.instagram.com/portalclickideia/" target="_blank" rel="noreferrer" className="text-white hover:text-gray-200">
            <FaInstagram size={24} />
          </a>
          <a href="https://www.linkedin.com/company/clickideia/" target="_blank" rel="noreferrer" className="text-white hover:text-gray-200">
            <FaLinkedin size={24} />
          </a>
          <a href="https://www.youtube.com/user/PortalClickideia" target="_blank" rel="noreferrer" className="text-white hover:text-gray-200">
            <FaYoutube size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from 'react';
import EnviarToken from '../verificaToken/EmailSent';
import EmailNaoPermitido from './EmailNaoPermitido';
import EmailJaCadastrado from './EmailJaCadastrado';
import { FaSpinner } from 'react-icons/fa';

const VerificacaoDeEmail = () => {
  const [email, setEmail] = useState('');
  const [enviarToken, setEnviarToken] = useState(null);
  const [emailInvalido, setEmailInvalido] = useState(false);
  const [emailJaCadastrado, setEmailJaCadastrado] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      email.endsWith('@prof.edu.saoleopoldo.rs.gov.br') ||
      email.endsWith('@aluno.edu.saoleopoldo.rs.gov.br') ||
      email.endsWith('@clickideia.com') ||
      email.endsWith('@clickideia.com.br')
    ) {
      window.api.post('/registration/verify-email', { email })
        .then(response => {
          setIsLoading(false);
          setEnviarToken(true);
        })
        .catch(error => {
          setIsLoading(false);
          if (error?.response?.status === 403 && error?.response?.data?.message === "Email ja cadastrado") {
            setEmailJaCadastrado(true);
          } else {
            console.log(error);
            window.showGlobalAlert('Ocorreu um erro. Por favor, tente novamente mais tarde', 'error');
          }
        });
      setEmailInvalido(false);
    } else {
      setIsLoading(false);
      setEmailInvalido(true);
    }
  };

  const voltarParaEmail = () => {
    setEmailInvalido(false);
    setEmailJaCadastrado(false);
  };

  if (emailInvalido) {
    return <EmailNaoPermitido email={email} voltar={voltarParaEmail} />;
  }

  if (emailJaCadastrado) {
    return <EmailJaCadastrado email={email} voltar={voltarParaEmail} />;
  }

  return (
    <>
      {!enviarToken && (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-8 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto h-20 w-auto" src="/img/slLogo.png" alt="Clickideia" />
            <h2 className="text-center mt-4 text-2xl font-bold leading-9 tracking-tight text-[#44C2F8]">
              Autocadastro - São Leopoldo
            </h2>
          </div>
          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md text-left">
            <p className="mt-4 text-md leading-8 text-gray-900">
              Olá, professores e estudantes! Diante dos desafios enfrentados nos últimos dias, estamos aqui para apoiá-los. Vamos ajudá-los com o autocadastro na plataforma Clickideia.
            </p>
            <div className="bg-blue-50 text-gray-900 text-sm px-4 py-3 rounded relative mt-4" role="alert">
              Professores e estudantes de São Leopoldo possuem um endereço de e-mail terminado com: <strong>edu.saoleopoldo.rs.gov.br</strong>.
              <p className="mt-2 text-md leading-8 text-gray-900">
                Por exemplo: <strong>maria@aluno.edu.saoleopoldo.rs.gov.br</strong>
              </p>
            </div>
            <p className="mt-4 text-md leading-8 font-semibold text-gray-900">
              Para começar, insira o seu e-mail:
            </p>
          </div>
          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={handleEmailChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#44C2F8] sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="flex w-1/2 justify-center rounded-full bg-[#44C2F8] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3AB3E2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#44C2F8] disabled:opacity-50"
                >
                  {isLoading ? (
                    <FaSpinner className="animate-spin h-5 w-5 text-white" />
                  ) : (
                    'Continuar'
                  )}
                </button>
              </div>
              <p className="mt-3 text-sm leading-5 text-gray-500">
                * Caso seu e-mail já esteja cadastrado, você será redirecionado para a página de login do Portal.
              </p>
            </form>
            <p className="mt-5 text-center text-sm text-gray-500">
              Problemas para acessar?{' '}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSddUTnx2eZKKBjsf8kBOA7ZjvHnBQkrs0PZYUwsKd11PvO69g/viewform" target="_blank" rel="noreferrer" className="font-semibold leading-6 text-[#44C2F8] hover:text-indigo-500">
                Clique aqui
              </a>
            </p>
          </div>
        </div>
      )}
      {enviarToken && <EnviarToken email={email} />}
    </>
  );
};

export default VerificacaoDeEmail;

import React from 'react';

const EmailNaoPermitido = ({ email, voltar }) => {
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-2xl">
                <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10">
                    <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 mb-4">
                        O endereço de e-mail <span className="text-red-500">{email}</span> não atende aos critérios.
                    </h2>
                    <p className="text-center text-lg leading-8 text-gray-900 mb-4">
                        Você será redirecionado para a página de acesso ao portal. Deseja prosseguir?
                    </p>
                    <div className="mt-4 text-center text-base text-gray-500">
                        <p>
                            Por favor, verifique se digitou o endereço corretamente. Os endereços de e-mails digitados deverão terminar com
                            <strong> "edu.saoleopoldo.rs.gov.br"</strong>
                        </p>
                        <p className="mt-4">
                            Caso seja um professor ou estudante de São Leopoldo, mas não possua um e-mail neste formato, acesse a próxima tela clicando em "Prosseguir" e solicite seu cadastro em "Ajuda com acesso".
                        </p>
                        <p className="mt-4">
                            Caso não seja um professor ou estudante de São Leopoldo, você pode acessar normalmente, clicando em "Prosseguir" e digitando seu login e senha na página de acesso ao portal. Você ainda poderá solicitar o cadastro em "Ajuda com acesso".
                        </p>
                    </div>
                    <div className="mt-6 flex justify-center gap-4">
                        <button
                            onClick={voltar}
                            className="rounded-full bg-gray-400 px-4 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                        >
                            Voltar para o e-mail
                        </button>
                        <button
                            onClick={() => window.location.href = 'https://www.clickideia.com.br/login/'}
                            className="rounded-full bg-[#44C2F8] px-4 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3AB3E2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#44C2F8]"
                        >
                            Prosseguir
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailNaoPermitido;

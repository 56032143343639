import React, { useState, useEffect, useRef } from 'react';
import CadastroAluno from '../cadastrosForm/CadastroAluno';
import CadastroProfessor from '../cadastrosForm/CadastroProfessor';
import { FaSpinner } from 'react-icons/fa';

const EmailSent = ({ email }) => {
    const [token, setToken] = useState(new Array(6).fill(""));
    const [tokenApi, setTokenApi] = useState('');
    const [isValidToken, setIsValidToken] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isResendLoading, setIsResendLoading] = useState(false);
    const [timer, setTimer] = useState(60);
    const [tokenError, setTokenError] = useState('');
    const inputRefs = useRef([]);
    const intervalRef = useRef();

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }

        startTimer();

        return () => clearInterval(intervalRef.current);
    }, []);

    const startTimer = () => {
        intervalRef.current = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(intervalRef.current);
                    setIsResendDisabled(false);
                    return 60;
                }
                return prevTimer - 1;
            });
        }, 1000);
    };

    const handleChange = (element, index) => {
        const value = element.value.toUpperCase();
        setToken([...token.map((d, idx) => (idx === index ? value : d))]);
        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
        if (!value && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && token[index] === "") {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData('text');
        if (pasteData.length === token.length) {
            const newToken = pasteData.split('').slice(0, 6);
            setToken(newToken);
            newToken.forEach((char, index) => {
                if (inputRefs.current[index]) {
                    inputRefs.current[index].value = char;
                }
            });
            if (inputRefs.current[5]) {
                inputRefs.current[5].focus();
            }
        }
        e.preventDefault();
    };

    const handleTokenSubmit = async (e) => {
        e.preventDefault();
        setTokenError('');

        const tokenString = token.join("");
        if (tokenString.length < 6) {
            setTokenError('Por favor, insira o token completo.');
            return;
        }

        setIsLoading(true);

        try {
            const response = await window.api.post('/registration/check-token', { email, token: tokenString });
            if (response.status === 200) {
                setTokenApi(response?.data?.token);
                setIsValidToken(true);
            }
        } catch (error) {
            console.log(error);
            window.showGlobalAlert(error?.response?.data, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleResendToken = async () => {
        setIsResendLoading(true);
        try {
            await window.api.post('/registration/verify-email', { email });
            setIsResendDisabled(true);
            setTimer(60);
            startTimer();
            window.showGlobalAlert('Token reenviado com sucesso.', 'success');
        } catch (error) {
            console.log(error);
            window.showGlobalAlert('Erro ao reenviar o token. Tente novamente mais tarde.', 'error');
        } finally {
            setIsResendLoading(false);
        }
    };

    if (isValidToken) {
        if (email.endsWith('@prof.edu.saoleopoldo.rs.gov.br') || email.endsWith('@clickideia.com')) {
            return <CadastroProfessor token={tokenApi} email={email} />;
        } else if (email.endsWith('@aluno.edu.saoleopoldo.rs.gov.br') || email.endsWith('@clickideia.com.br')) {
            return <CadastroAluno token={tokenApi} email={email} />;
        }
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Token de autenticação
                </h2>
            </div>
            <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md text-center">
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                    Enviado para <strong>{email}</strong>
                </div>
                <p className="mt-4 text-md leading-8 text-gray-900">
                    Insira o token para continuar o seu cadastro
                </p>
            </div>
            <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
                <form className="space-y-6" onSubmit={handleTokenSubmit}>
                    <div className="flex justify-center space-x-2" onPaste={handlePaste}>
                        {token.map((data, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                className="w-12 h-12 text-center rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#44C2F8] sm:text-lg sm:leading-8"
                                value={data}
                                onChange={(e) => handleChange(e.target, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                ref={(el) => (inputRefs.current[index] = el)}
                            />
                        ))}
                    </div>
                    {tokenError && <p className="text-red-500 text-xs italic text-center">{tokenError}</p>}
                    <div className="flex justify-center mt-5">
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="flex w-1/2 justify-center rounded-full bg-[#44C2F8] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3AB3E2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#44C2F8] disabled:opacity-50"
                        >
                            {isLoading ? (
                                <FaSpinner className="animate-spin h-5 w-5 text-white" />
                            ) : (
                                'Confirmar'
                            )}
                        </button>
                    </div>

                </form>
                <div className="mt-4 text-center">
                    <button
                        onClick={handleResendToken}
                        disabled={isResendDisabled || isResendLoading}
                        className={`flex items-center justify-center mx-auto text-sm font-semibold ${isResendDisabled ? 'text-gray-500' : 'text-[#44C2F8] hover:text-indigo-500'}`}
                    >
                        {isResendLoading && <FaSpinner className="animate-spin h-5 w-5 mr-2" />}
                        Reenviar token via e-mail {isResendDisabled && `(${timer}s)`}
                    </button>
                </div>
                <div className="mt-4 text-center text-sm text-gray-500">
                    <p>
                        Ao clicar em confirmar, você concorda com a{' '}
                        <a href="https://www.home.clickideia.com.br/pol%C3%ADtica-de-privacidade" target="_blank" rel="noreferrer" className="font-semibold text-[#44C2F8] hover:text-indigo-500">
                            Política de Privacidade
                        </a>{' '}
                        da Clickideia.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EmailSent;

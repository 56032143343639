import React from 'react';

const CadastroSucesso = ({ email }) => {

    const handleClose = () => {
        window.location.reload();
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
            </div>
            <div className="p-4 mt-8 mx-auto max-w-4xl w-full">
                <div className="flex flex-col items-center">
                    <h2 className="text-2xl font-bold leading-9 tracking-tight text-[#44C2F8] mb-4">
                        Cadastro concluído!
                    </h2>
                    <p className="text-md leading-8 text-gray-900 mb-4 text-center">
                        Por favor, verifique seu e-mail para as instruções finais.
                    </p>
                    <p className="text-md leading-8 text-gray-900 mb-4 text-center break-words">
                        Um e-mail foi enviado para o endereço <strong className="block text-base sm:text-lg">{email}</strong> contendo seu login e senha.
                    </p>
                    <p className="text-md leading-8 text-gray-900 mt-4 text-center">
                        Siga as instruções para ativar sua conta.
                    </p>
                    <div className="flex justify-center mt-5">
                        <button
                            onClick={handleClose}
                            className="flex w-50 justify-center rounded-full bg-[#44C2F8] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3AB3E2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#44C2F8]"
                        >
                            Voltar à página inicial
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CadastroSucesso;

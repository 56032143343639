import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const AlertComponent = ({ open, message, severity, onClose }) => (
  <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
    <MuiAlert onClose={onClose} severity={severity} sx={{ width: '100%' }} elevation={6} variant="filled">
      {message}
    </MuiAlert>
  </Snackbar>
);

export default AlertComponent;
